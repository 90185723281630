<template>
    <v-container fluid class="main-scoreboard-container">
        <v-container >
            <v-row  v-if='User && User.role == "student"'>
                <StudentActivityScoreboard class="scoreboard"
                    v-bind:scoresData="scoresData" 
                    v-bind:scoresHeading="scoresHeading"
                    v-bind:from="currentRouteName"     
                >
                </StudentActivityScoreboard>
            </v-row>    
            <v-row v-if='User && User.role == "teacher"'>
                <ClassPerformanceScoreboard class="scoreboard"
                    v-bind:performanceData="performanceData" 
                    v-bind:performanceHeading="performanceHeading"
                    v-bind:from="currentRouteName"      
                >
                </ClassPerformanceScoreboard>
            </v-row>
            <v-row class="footer">
                <BackBtn @close="goBack()"></BackBtn> 
            </v-row>      
        </v-container>
    </v-container>
</template>

<script>

import StudentActivityScoreboard from '../components/StudentActivityScoreboard';
import ClassPerformanceScoreboard from '../components/ClassPerformanceScoreboard';
import BackBtn from '@/components/BackBtn';
// import BackBtn from '../components/BackBtn.vue';

export default {
    name: 'Scoreboard',
    data(){
        return{
            /**  props for StudentActivityScoreboard */
            scoresHeading:[
                // {column1: "Username", column2: "Coins", column3: "Time (Minutes:Seconds)"}
                {column1: "Username", column2: "Coins"}
            ],
            scoresData: [
                {user: "John", coin: "5"},
                {user: "Sam", coin: "5"},
                {user: "David", coin: "4"},             
                {user: "Sophia", coin: "3"},              
                {user: "Joe", coin: "5"},              
                {user: "Jorge", coin: "1"},              
                {user: "Dan", coin: "5"},              
            ],
            /**  props for AssessmentScoreboard */
            assessmentHeading:[
                {column1: "Unit", column2: "Question", column3: "Correct"}            
            ],
            assessmentData: [
                {unit: "Earth and Space", question: "How long is MW?", correct: '33%'}             
            ],
            /**  props for ClassPerformanceScoreboard */
            performanceHeading:[
                {column1: "Class", column2: "Coins", column3: "Average", column4: "#FFFFFF", column5:"Time (Minutes:Seconds)"}
            ],
            performanceData: [                                  
                {class: "William", coin: "5", average: "16%", color:"#EC0C19", time:"07:10"},             
                {class: "Evelyn", coin: "5", average: "60%", color:"#F2C94C", time:"08:15"},             
                {class: "James", coin: "5", average: "73%", color:"#F2C94C", time:"06:55"},             
                {class: "Harper", coin: "5", average: "37%", color:"#EC0C19", time:"01:01"},             
                {class: "Mason", coin: "2", average: "99%", color:"#23C033", time:"04:08"},             
                {class: "Ella", coin: "5", average: "50%", color:"#F2C94C", time:"03:50"},             
                {class: "Jackson", coin: "3", average: "80%", color:"#23C033", time:"07:13"},             
            ],
        }
    },
    components: {
        StudentActivityScoreboard,
        ClassPerformanceScoreboard,
        BackBtn
    },
    methods:{
        goBack(){
            if(this.$store.state.fromLink.name == 'Activity' || this.$store.state.fromLink.name == 'Lessons'){
                this.$router.push({ name: 'Lessons', params: { id: '6a6b5c5d9e9', name: 'Earth and Space', slug: 'earth-and-space' } })
            }
            else{
                this.$router.push({name: 'Home'});
            }
        }
    },
    computed:{
        User(){
            return this.$store.state.user;
        },
        currentRouteName(){
            return this.$route.name;
        }
    },
    mounted(){
        if(this.$store.state.fromLink.name){
            if(this.$store.state.fromLink.name == 'Activity' || this.$store.state.fromLink.name == 'Lessons'){
                localStorage.setItem("fromLink", JSON.stringify(this.$store.state.fromLink));
            }
            else{
                localStorage.setItem("fromLink", JSON.stringify(this.$store.state.fromLink));
            }
        }
    }
}
</script>

<style scoped>
    .main-scoreboard-container{
        padding: 0;
        background: #000000;
        height: 100%;
    }

    .scoreboard{
        padding-top: 2rem;
    }

    .footer{
        position: absolute;
        bottom: 0.5rem;
        left: 2rem;
    }
 
</style>
