<template>
    <v-container fluid >
        <div class="text-center">
            <v-btn text class="header-btn" >
                Class Performance
            </v-btn>                
        </div>
        <div class="performance-data-container">
            <v-row class="performance-data-heading"
                v-for= "(performance, index) in performanceHeading"
                v-bind:key="'h'+index"
            >
                <v-col cols="1"> </v-col>
                <v-col cols="2">
                    {{performance.column1}}
                </v-col>
                <v-col cols="2">
                    {{performance.column2}}
                </v-col>
                <v-col cols="2">
                    {{performance.column3}}
                </v-col>
                 <v-col cols="2">
                    <v-btn class="color-btn" fab small v-bind:style="{'background-color': '+ {{performance.column4}} +'}">
                    </v-btn>
                </v-col>
                <v-col cols="2"> 
                    {{performance.column5 }}
                </v-col>
                <v-col cols="1"> </v-col>
            </v-row>
            <v-row  
                v-for= "(performance, index) in splitAndShuffleArr(performanceData)"
                v-bind:key="index"
            >
                <v-col cols="1"> </v-col>
                <v-col cols="2">
                    {{performance.class}}
                </v-col>
                <v-col cols="2">
                    {{performance.coin}}
                </v-col>
                <v-col cols="2">
                    {{performance.average}}
                </v-col>                
                <v-col cols="2">
                    <v-btn class="color-btn" fab small :color="`${performance.color}`">
                    </v-btn>
                </v-col>
                <v-col cols="2"> 
                    {{performance.time}}
                </v-col>
                <v-col cols="1"> </v-col>
            </v-row>
        </div>
    </v-container>
</template>
<script>
export default {
    name: 'ClassPerformanceScoreboard',
    props: ["performanceData", "performanceHeading"],
    data(){
        return{
            
        }
    },
    methods: {
        splitAndShuffleArr: function(rand){
            rand =  rand.slice([Math.floor(Math.random() * rand.length)]);
            return rand.sort(function(){return 0.5 - Math.random()});
        }
    }  
}
</script>

<style scoped>
    .header-btn{
        background-color: #23C033;
        padding: 1.5rem 5rem  !important;
        font-size: 25px;
        font-weight: 400;
    }

    .performance-data-container{
        color: #ffffff;
        margin-top: 3rem;
    }

    .performance-data-heading{
        text-transform: uppercase;
        font-weight: 700;
    }

    .color-btn{
        width: 30px!important;
        height: 30px!important;
    }
</style>