<template>
    <v-container fluid>
        <div class="text-center">
            <v-btn text class="score-btn" >
                Scores
            </v-btn>                
        </div>
        <div class="score-data-container">
            <v-row class="score-data-heading"                 
                v-for="(scoreHeading, index) in scoresHeading"
                v-bind:key="'h'+index"
            >
                <v-col cols="3"> </v-col>
                <v-col cols="4">
                    {{scoreHeading.column1}}
                </v-col>
                <v-col cols="1"> </v-col>
                <v-col cols="3">
                    {{scoreHeading.column2}}
                </v-col>
                <v-col cols="1"> </v-col>
            </v-row>
            <v-row  
                v-for="(score, index) in splitAndShuffleArr(scoresData)"
                v-bind:key="index"
            >
                <v-col cols="3"> </v-col>
                <v-col cols="4">
                    <p :class="{currentUserScore: score.user == getUser.name}">{{score.user}}</p>
                </v-col>
                <v-col cols="1"></v-col>
                <v-col cols="3">
                    <p :class="{currentUserScore: score.user == getUser.name}">{{score.coin}}</p>
                </v-col>
                <v-col cols="1"></v-col>
            </v-row>
            <v-row>
                <transition name="fade-transition">
                    <v-btn v-if="nextActivity && $store.state.fromLink.name != 'Home'" class="skip custom-font" @click="goToNextActivity" :disabled="btnDisabled">Next Activity</v-btn>
                    <v-btn v-if="!nextActivity && $store.state.fromLink.name != 'Home'" class="skip custom-font" @click="goToNextActivity" :disabled="btnDisabled">Go to Lessons</v-btn>
                </transition>
            </v-row>
        </div>

    </v-container>
</template>
<script>
export default {
    name: 'StudentActivityScoreboard',
    props: ["scoresData", "scoresHeading", "from"],
    data(){
        return{   
            btnDisabled: false
        }
    },
    methods: {
        splitAndShuffleArr: function(rand){
            rand =  rand.slice([Math.floor(Math.random() * rand.length)]);
            rand.push({user: this.getUser.name, coin: this.getCoins, time: this.getTime});
            return rand.reverse(rand.sort((a, b) => a.coin - b.coin));
        },
        goToNextActivity(){
            if(this.nextActivity){
                this.btnDisabled = true;
                this.$router.push(
                    {
                        name: 'Activity', 
                        params: {id: this.$store.state.nextActivity.id, name: this.$store.state.nextActivity.name, lessonID: this.$route.params.lessonID}
                    }
                );
            }else{
                this.btnDisabled = true;
                this.$router.push({ name: 'Lessons', params: { id: '6a6b5c5d9e9', name: 'Earth and Space', slug: 'earth-and-space' } })
            }
        }
    },
    computed:{
        getUser(){
            return this.$store.state.user;
        },
        getTime(){
            return this.$store.state.activityTime;
        },
        getCoins(){
            return this.$store.state.assessmentCoins;
        },
        nextActivity(){
            return this.$store.state.nextActivity;
        }
    }
}
</script>

<style scoped>

    .score-btn{
        background-color: #23C033;
        padding: 1.5rem 5rem  !important;
        font-size: 25px;
        font-weight: 400;
    }

    .score-data-container{
        color: #ffffff;
        margin-top: 3rem;
    }

    .score-data-heading{
        text-transform: uppercase;
        font-weight: 700;
    }
    .currentUserScore {
        color: #23C033;
        font-size: 17px;
        font-weight: 500;
    }
</style>